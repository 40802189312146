import React from "react";
import Content from '../views/Contact';

export default ({location}) => <Content location={location} seo={{
  title: 'Welcome to Traffilog | Contact us to Explore Your Interest ',
  lang: 'en',
  description: 'Welcome to Traffilog! Contact us to explore your interest in our advanced connected vehicle technology solutions. Let\'s connect and revolutionize your fleet management today.',
  meta: [{
    name: 'keywords',
    content: 'Contact Traffilog, product tour, connected vehicle solutions, automotive technology '
  }]
}}/>