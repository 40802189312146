import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import styles from './styles.module.sass';

export default ({ image, name, position, linkedIn, topics }) => (
    <Grid item xs={12} sm={12} md={6} xl={4} className={styles.listContainer}>
        <div className={styles.featureItem}>
            <div className={styles.infoWrap}>
                <Img imgStyle={{ objectFit: 'none'}}
                    objectFit='none'
                    objectPosition='50% 50%'
                    className={styles.featureImage}
                    fluid={image.fluid}
                    alt={name}/>

                <div className={styles.metaWrap}>
                    {linkedIn
                        ? <a href={linkedIn} target="_blank" rel="noreferrer" className={styles.personName}>
                            <svg className={styles.iconViewBox} viewBox="0 0 24 24">
                                <path d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />
                            </svg>
                            {name}
                        </a>
                        : <div className={styles.personName}>{name}</div>
                    }
                    {position
                        ? <div className={styles.personPosition}>{position}</div>
                        : null
                    }
                </div> 
            </div>

            {topics && topics.length
                ? <div className={styles.topicsWrap}>
                    <div className={styles.topicsTitle}>We can talk about</div>
                    {topics.map((t) => <div className={styles.topic}>- {t}</div>)}
                </div>
                : null
            }          
            
        </div>
    </Grid>
);