import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Card from './Card';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        karsten: imageSharp(fluid: { originalName: {eq: "contact_01_karsten.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        soren: imageSharp(fluid: { originalName: {eq: "contact_02_soren.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        mario: imageSharp(fluid: { originalName: {eq: "contact_03_mario.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        george: imageSharp(fluid: { originalName: {eq: "contact_04_george.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        helle: imageSharp(fluid: { originalName: {eq: "contact_05_helle.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        nicolai: imageSharp(fluid: { originalName: {eq: "contact_06_nicolai.jpg"} }) {
          fluid(maxWidth: 276, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ karsten, soren, mario, george, helle, nicolai }) => {
      return (<SectionWrapper className={styles.container}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={12} className={styles.textContainer}>
              <Typography variant='h3'
                weight='bold'
                className={styles.title}>
                {i18n('Some of the people you will meet are:')}
              </Typography>
            </Grid>
          
          </Grid>

          <Grid container alignItems='center' className={styles.rowContainer}>
            <Card
              image={karsten}
              name={'Karsten W. Hjorth'}
              position={'CEO'}
              linkedIn={'https://www.linkedin.com/in/karsten-hjorth-b3b41b11/'}
              topics={[ i18n('Future of mobility'), i18n('New projects'), i18n('Strategy'), i18n('Our story'), i18n('Fast cars') ]}
            />

            <Card
              image={nicolai}
              name={'Nicolai Paulsen'}
              position={'Deputy CEO'}
              linkedIn={'https://www.linkedin.com/in/nicolaipaulsen/'}
              topics={[ i18n('IoT and Software Development'), i18n('Future Mobility strategy'), i18n('Operational processes'), i18n('CSR'), i18n('Kite surfing') ]}
            />

            {/* <Card
              image={soren}
              name={'Søren Reinewald'}
              position={'COO'}
              linkedIn={'https://www.linkedin.com/in/reinewald/'}
              topics={[ i18n('Requirements'), i18n('Solutions'), i18n('Partnerships'), i18n('Reference cases'), i18n('Padel tennis') ]}
            /> */}

            <Card
              image={mario}
              name={'Mario Filchev'}
              position={'CCO'}
              linkedIn={'https://www.linkedin.com/in/mariofilchev/'}
              topics={[ i18n('Ideas'), i18n('Needs and scope'), i18n('Product tour'), i18n('Planning'), i18n('Football') ]}
            />

            <Card
              image={george}
              name={'George Jordanov'}
              position={'CTO'}
              linkedIn={'https://www.linkedin.com/in/gjordanov/'}
              topics={[ i18n('The platform'), i18n('Technology needs'), i18n('Implementation'), i18n('New development'), i18n('Cycling') ]}
            />

            <Card
              image={helle}
              name={'Helle Bang'}
              position={'CFO'}
              linkedIn={'https://www.linkedin.com/in/helle-bang-8220b152/'}
              topics={[ i18n('The company'), i18n('Cooperation'), i18n('Joining us'), i18n('Our day-to-day'), i18n('Golf') ]}
            />
          </Grid>

      </SectionWrapper>
  )}}/>
);