import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export default ({ onClose, onChange, onSubmit, dialogOpen, formData, error, success }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
return (
    <Dialog open={dialogOpen} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>

      <div className={styles.dialogTop}>{i18n('Request a meeting')}</div>
      
      {success
        ? <div className={clsx(styles.dialogContent, styles.successMessage)}>
          {i18n('Thank you for your request. A member of our team will follow up soon.')}
        </div>
      
        : <div className={styles.dialogContent}>

          <Typography variant='h2'
            weight='bold'
            color='dark'
            className={styles.dialogTitle}>
            {i18n('How should we get in contact with you?')}
          </Typography>
          <Typography className={styles.subtitleContainer}
            variant='subtitle1'
            weight='light'
          >
            {i18n('We’re looking forward to getting in touch soon. Please leave a note if you have any specific topic/request in mind.')}
          </Typography>
          
          <div className={styles.container}>
            <div className={styles.inputLabel}>
              {i18n('Email address')}&nbsp;*
            </div>
            <input type='email' className={styles.input}
              defaultValue={formData.email || ''}
              onChange={e => onChange('email', e.target.value)}/>
          </div>
          
          <div className={styles.container}>
            <div className={styles.inputLabel}>
              {i18n('Name')}&nbsp;*
            </div>
            <input type='text' className={styles.input}
              defaultValue={formData.name || ''}
              onChange={e => onChange('name', e.target.value)}/>
          </div>
          
          <div className={clsx(
              styles.container,
              styles.nameContainer
            )}>
            <div className={styles.inputLabel}>
              {i18n('Telephone number (optional)')}
            </div>
            <input type='tel' className={styles.input}
              defaultValue={formData.phone || ''}
              onChange={e => onChange('phone', e.target.value)}/>
          </div>

          <div className={clsx(
              styles.container,
              styles.messageContainer
            )}>
            <div className={styles.inputLabel}>
              {i18n('Leave a note')}
            </div>
            <textarea onChange={e => onChange('message', e.target.value)} className={styles.input}>

            </textarea>
          </div>
          
          <Typography className={styles.termsContainer}
            variant='subtitle1'
            weight='light'
            color='dark'>
            {i18n('By clicking \'Send\', you consent to Traffilog Nordic ApS storing and processing the personal information you have provided above to get in contact with you and inform you about our services. ')}
            <br/>
            <br/>
            {i18n('You can unsubscribe at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our')}&nbsp;
            <a href='/privacy-policy'
              target='_blank'
              className={styles.anchor}>
              {i18n('Terms of Use and Privacy Policy')}
            </a>.
          </Typography>

          {error
            ? <Typography variant='subtitle2'
              align='center'
              className={styles.error}
              color='alarm'>
              {i18n('Whoops! Something went wrong. Please try again...')}
            </Typography>
            : null
          }

        </div>
      }

      <div className={styles.actions}>
        <Button className={styles.button} onClick={onClose}>
              {i18n(success ? 'Close' : 'Cancel')}
        </Button>
        {success
          ? null
          : <Button className={styles.button} onClick={onSubmit} disabled={!formData.email || !validateEmail(formData.email) || !formData.name}>
                {i18n('Send request')}
          </Button>
        }
      </div>
    </Dialog>
)};