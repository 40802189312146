import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import i18n from '../../locale';
import Hero from './Hero';
import Form from './Form';
import People from './People';
import FollowUs from "./FollowUs";
import CallToAction from '../CallToAction';
import sitemap from '../../data/sitemap';

const postToForm = data => fetch(
  'https://api.hsforms.com/submissions/v3/integration/submit/5142926/856c0f88-d86c-464d-80c1-a3bf3727f269',
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'submittedAt': Date.now(),
      'fields': [
        {
          'name' : 'email',
          'value': data.email
        }, {
          'name' : 'phone',
          'value': data.phone ? data.phone : ''
        }, {
          'name': 'firstname',
          'value': data.name ? data.name : ''
        }, {
          'name' : 'requesttype',
          'value': data.request || ''
        }, {
          'name' : 'message',
          'value': data.message || ''
        }
      ],
      'context': {
        'pageUri': i18n('_url:_') + i18n('_url:lets-connect'),
        'pageName': 'Let\'s connect'
      },
      'legalConsentOptions': {
        "consent": {
          "consentToProcess": true,
          "text": "I agree to allow Traffilog Nrodic to store and process my personal data.",
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 999,
              "text": "I agree to receive marketing communications from raffilog Nrodic"
            }
          ]
        }
      }
    })
});

export default ({ seo, location }) => {
  const [ error, setError ] = React.useState(false);
  const [ success, setSuccess ] = React.useState(false);
  const [ dialogOpen, setDialogOpen ] = React.useState(false);
  const [ formData, setFormData ] = React.useState({});
  
  const setFormKey = (key, data) => setFormData({
    ...formData,
    [key]: data
  });

  const showDialog = (request) => {
    setFormKey('request', request);
    setError(false);
    setSuccess(false);
    setDialogOpen(true);
  }

  console.log('formData', formData);

  return <Layout overlay page={sitemap.letsConnect}
    url='_url:lets-connect'>
    <SEO {...seo} location={location}/>
    <Hero showDialog={showDialog}/>
    <Form
      onClose={() => setDialogOpen(false)}
      onChange={setFormKey}
      dialogOpen={dialogOpen}
      formData={formData}
      error={error}
      success={success}
      onSubmit={() => postToForm(formData)
        .then(res => res.json()).then(res => {
          if(res.status !== 'error') {
            setSuccess(true);
            setError(false);
          } else setError(true);
        }).catch(() => setError(true))}
    />
    <People />
    <FollowUs />
    <CallToAction/>
  </Layout>
};