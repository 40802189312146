import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Feature from './Feature';
import styles from './styles.module.sass';

export default ({ showDialog }) => (
  <StaticQuery
    query={graphql`
      query {
        header: imageSharp(fluid: {originalName: {eq: "contactus_01_hero.png"}}) {
          fluid(maxHeight: 660, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon1: imageSharp(fluid: { originalName: {eq: "contactus_02_icon1.jpg"} }) {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon2: imageSharp(fluid: { originalName: {eq: "contactus_03_icon2.jpg"} }) {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon3: imageSharp(fluid: { originalName: {eq: "contactus_04_icon3.jpg"} }) {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ header, icon1, icon2, icon3}) => {
      return (
      <SectionWrapper id='main'>
        <Grid container alignItems='center'
          className={styles.container}
          style={{ backgroundImage: `url(${header.fluid.src})`}}
        >
          {/* <Grid item xs={12}
            className={clsx(styles.imageContainer, styles.imageContainerMobile)}
          >
            <Img imgStyle={{ objectFit: 'none'}}
              objectFit='none'
              objectPosition='top right'
              className={styles.image}
              fluid={header.fluid}
              alt={i18n('Let’s take the first step.')}/>
          </Grid> */}
          <Grid item xs={12}
            className={styles.textContainer}>
            <Typography
              variant='h1'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitch,
                styles.heroText
              )}>
              {i18n('Let’s take the first step.')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('We are flexible. You can send request to book a meeting or write directly to any of us.')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems='center' className={styles.cardsContainer}>

          <Grid item xs={12} sm={12} md={4} className={styles.listContainer}>
            <Feature
              image={icon1}
              title={i18n('Intro meeting')}
              text={i18n('Say hello and talk about your interests')}
              action={() => showDialog('Intro meeting')}
              ctaLabel={i18n('Book intro meeting')}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} className={styles.listContainer}>
            <Feature
              image={icon2}
              title={i18n('Consultation')}
              text={i18n('Explore a specific project in more details')}
              action={() => showDialog('Consultation')}
              ctaLabel={i18n('Book consultation meeting')}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} className={styles.listContainer}>
            <Feature
              image={icon3}
              title={i18n('Product tour')}
              text={i18n('Let’s show you our product portfolio')}
              action={() => showDialog('Product tour')}
              ctaLabel={i18n('Book product tour meeting')}
            />
          </Grid>

        </Grid>
      </SectionWrapper>
    )}}/>
);